<template>
	<div class="home teacher-home">
		<div id="hero" class="teacher-hero">
			<p class="title">Find a Teacher</p>

            <form class="teacher-search-box" @submit.prevent>
				<input placeholder="Search for a location" type="text" ref="searchBox"/>
			</form>
		</div>

		<div class="teacher-search-container">
			<div class="teacher-list-container" :class="{ closed: !listOpen }">
				<mq-layout :mq="['xs', 'sm']">
					<div class="top-button-container">
						<button class="button-round" @click="listToggle">{{ listOpen ? 'Hide list' : 'Show list'}}</button>
					</div>
				</mq-layout>

				<div class="scroll-container">
					<p class="results-heading" v-if="teachersFound.length > 0"><span>{{ teachersFound.length }}</span> {{ teachersFound.length == 1 ? 'Result' : 'Results' }} found</p>
					<p class="results-heading" v-else>Enter your location to find teachers near you. You can also zoom or move the map to search.</p>

					<div class="teacher-results-list">	
						<div class="teacher-result" v-for="(teacher, i) in teachersFound" :key="i" :class="{'academy': teacher.type === 2}" @click="goTo(teacher.id)">
							<img :src="teacher.image" class="teacher-image" v-if="teacher.image">
							<p class="name"><span>{{ i + 1 }}</span>{{ teacher.name ? teacher.name : teacher.teacherName }}</p>
							<p class="person" v-if="teacher.name">{{ teacher.teacherName }}</p>
							<p class="address">{{ teacher.address }}</p>
							<p class="number">{{ teacher.phone }}</p>
							<p class="number" v-if="teacher.alternatePhone">{{ teacher.alternatePhone }}</p>
							
							<a class="email button-round small" :href="'mailto:'+teacher.email">mail_outline</a>
							<a class="website button-round small" :href="teacher.website" v-if="teacher.website" target="_blank">language</a>

							<div class="icons-row">
								<img src="@/assets/images/badges/MBM.png" v-if="teacher.kb">
								<img src="@/assets/images/badges/PP.png" v-if="teacher.pp">
								<img src="@/assets/images/badges/KBP.png" v-if="teacher.kbp">
								<img src="@/assets/images/badges/JR.png" v-if="teacher.jr">
								<img src="@/assets/images/badges/PR.png" v-if="teacher.pr">
								<img src="@/assets/images/badges/ACC.png" v-if="teacher.acc">
								<img src="@/assets/images/badges/ACH.png" v-if="teacher.ach">
								<img src="@/assets/images/badges/ST.png" v-if="teacher.st">
								<img src="@/assets/images/badges/TW.png" v-if="teacher.tw">
							</div>

							<p class="distance" v-if="currentLat !== null">{{ teacher.distance }} km</p>
						</div>
					</div>
				</div>
			</div>

			<div id="map" ref="map"></div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
	data() {
		return {
			map: null,
			autocomplete: null,
			markers: [],
			teachersFound: [],
			currentLat: null,
			currentLng: null,
			listOpen: false
		}
	},
	computed: {
		...mapState('teachers', { teacherList: 'list' })
	},
	methods: {
		listToggle() {
			this.listOpen = !this.listOpen
		},
		initMaps() {
			const uluru = { lat: -25.344, lng: 131.036 };

			this.map = new window.google.maps.Map(this.$refs['map'], {
				zoom: 4,
				center: uluru,
				streetViewControl: false,
				fullscreenControl: false
			});

			this.initAutocomplete();

			this.map.addListener("bounds_changed", () => {
				const bounds = this.map.getBounds();

				this.teachersFound = this.teacherList.map(t => ({ id: t.id, ...t })).filter(teacher => {
					return bounds.contains({
						lat: teacher.lat,
						lng: teacher.lng
					}) && teacher.address !== "" && !teacher.inactive;
				}).map(teacher => {
					return {
						...teacher,
						distance: this.distance(teacher.lat, teacher.lng, this.currentLat, this.currentLng)
					};
				}).sort((a, b) => {
					if (a.type === undefined) {
						a.type = 0;
					}

					if (b.type === undefined) {
						b.type = 0;
					}

					if (a.type === b.type) {
						return a.distance - b.distance;
					} else {
						return b.type - a.type;
					}
				});
			});
		},
		distance(lat1, lon1, lat2, lon2) {
			const R = 6371e3; // metres
			const φ1 = lat1 * Math.PI/180; // φ, λ in radians
			const φ2 = lat2 * Math.PI/180;
			const Δφ = (lat2-lat1) * Math.PI/180;
			const Δλ = (lon2-lon1) * Math.PI/180;

			const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
					Math.cos(φ1) * Math.cos(φ2) *
					Math.sin(Δλ/2) * Math.sin(Δλ/2);
			const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

			const d = R * c; // in metres

			return (d / 1000).toFixed(1);
		},
		initAutocomplete() {
			this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs['searchBox'])
			this.autocomplete.setFields(["geometry"])

			this.autocomplete.addListener("place_changed", () => {
				const place = this.autocomplete.getPlace();

				this.currentLat = place.geometry.location.lat();
				this.currentLng = place.geometry.location.lng();

				this.map.setCenter(place.geometry.location);
				this.map.setZoom(12);
			})
		},
		goTo(id) {
			let teacher = this.teacherList.find(t => t.id === id);
			this.map.setCenter({ lat: teacher.lat, lng: teacher.lng});
			this.map.setZoom(17);

			this.listOpen = false;
		},
		...mapActions('teachers', ['bindList'])
	},
    metaInfo() {
		return {
			script: [{
				src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAXypMM2uB-mioDJ-xX5sCSEAL2fiZGgMY&libraries=places',
				async: true,
				defer: true,
				callback: () => this.initMaps()
			}]
		}
	},
	mounted() {
		this.bindList();

		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		let vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});
	},
	watch: {
		teachersFound(newList) {
			this.markers.forEach(marker => {
				marker.setMap(null);
			})

			this.markers = [];

			newList.forEach((teacher, i) => {
				if (teacher.lat == 0 && teacher.lng == 0) {
					return;
				}

				const marker = new window.google.maps.Marker({
					position: { 
						lat: teacher.lat,
						lng: teacher.lng,
					},
					title: teacher.name ? teacher.name : teacher.teacherName,
					map: this.map,
					label: String(i + 1)
				});

				const infoWindow = new window.google.maps.InfoWindow({
					content: `<p class="map-info">${teacher.name ? teacher.name : teacher.teacherName}</p>`
				});

				marker.addListener('click', () => {
					infoWindow.open(this.map, marker);
				})

				this.markers.push(marker);
			});
		}
	}
}
</script>